// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  hmr: false,
  version: env.npm_package_version + '-beta',
  serverUrl: 'https://api.chucknorris.io',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  firebase: {
    apiKey: 'AIzaSyAjMaTdEUf-acVb3lxsd6SJ8C1ocUEEYa0',
    authDomain: 'trust-11b7d.firebaseapp.com',
    databaseURL: 'https://trust-11b7d.firebaseio.com',
    projectId: 'trust-11b7d',
    storageBucket: 'trust-11b7d.appspot.com',
    messagingSenderId: '559041043219',
    appId: '1:559041043219:web:41504232bf1c679ad558e6'
  },
  TECNOTRUST_NOTIFICATIONS: 'https://notifications.tecnotrust.club',
  CLOUD: 'https://us-central1-trust-11b7d.cloudfunctions.net',
  APP_NAME: 'Tecnotrust',
  GROUP: 'sumitomo'
};
