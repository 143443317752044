import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Import canActivate guards
import { AuthGuard } from './shared/auth/auth.guard';
import { InnerGuard } from './shared/inner-guard/inner-guard.guard';

// Required components for which route services to be activated
import { HomePage } from '../app/home/home.page';
import { LoginPage } from '../app/login/login.page';

// Include route guard in routes array
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [InnerGuard]
  },
  {
    path: '**',
    redirectTo: '',
    canActivate: [AuthGuard]
  },
  {
    path: 'status',
    loadChildren: () => import('./status/status.module').then( m => m.StatusPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
