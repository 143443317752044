import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any;

  constructor(
    public afAuth: AngularFireAuth,
    public http: HttpClient,
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning,
    public alertController: AlertController,
    public afs: AngularFirestore
  ) {
    // Setting logged in user in localstorage else null
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
        this.router.navigate(['home']);
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
        this.router.navigate(['login']);
      }
    });
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null ? true : false;
  }

  async loginEmail(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    const alert = await this.alertController.create({
      header: '¿Deseas cerrar sesión?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Ok',
          handler: (data: any) => {
            this.signOut();
          }
        }
      ]
    });

    await alert.present();
  }

  // Sign out
  signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
    });
  }

  async isAllowed() {
    const ip = await this.getIP();
    const isAllowed = await this.getAllowed(ip);

    return isAllowed.size > 0 ? true : false;
  }

  async getIP() {
    let ip = {
      ip: null
    };

    try {
      ip = await this.http
        .get<{ ip: string }>('https://api64.ipify.org?format=json')
        .toPromise();
    } catch (error) {
      ip = {
        ip: null
      };
    }

    return ip.ip;
  }

  async getAllowed(ip: string) {
    const ips = await this.afs
      .collection('/third-party/diatel/allowed-ip', ref =>
        ref.where('ip', '==', ip)
      )
      .get()
      .toPromise();
    return ips;
  }
}
