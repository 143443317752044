import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth.service';
import { ForbiddenPage } from 'src/app/forbidden/forbidden.page';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    public modalController: ModalController
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.authService.isLoggedIn !== true) {
      this.router.navigate(['login']);
    }

    const isAllowed = await this.authService.isAllowed();

    if (!isAllowed) {
      await this.authService.signOut();
      const modal = await this.modalController.create({
        component: ForbiddenPage,
        cssClass: 'custom-modal',
        backdropDismiss: false
      });
      await modal.present();
    }

    return true;
  }
}
